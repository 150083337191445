.courses-card{
    background: #FFFFFF;
    box-shadow: 0 2px 18px rgba(62, 74, 97, 0.12);
    border-radius: 12px;
    padding: 20px 24px;
    display: flex;
}
.courses-card__caption {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.courses-card__img {
    width: 182px;
    height: 146px;
    background: gray;
    border-radius: 16px;
    margin-right: 24px;
    overflow: hidden;
}
.courses-card__img img {
    width: 100%;
}
.courses-card__title{
    display: flex;
    align-items: baseline;
    gap: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #050A46;
}
.courses-card__title img {
    width: 20px;
    height: 20px;
}

.courses-card__operations{
    display: flex;
    gap: 32px;
}
.courses-card__operations button {
    background: unset;
    border: unset;
    display: flex;
    align-items: center;
    gap: 8px;
}