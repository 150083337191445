.card__showcase {
    background: #FFFFFF;
    box-shadow: 0px 2px 18px rgba(62, 74, 97, 0.12);
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 32px;
    height: fit-content;
}

.card__showcase-img {
    width: 182px;
    height: 146px;
    border-radius: 16px;
    overflow: hidden;
}

.card__showcase-img img {
    width: 100%;
    height: 100%;
}

.card__showcase-operations {
    display: flex;
    justify-content: space-around;
    padding-top: 12px;
}

.card__showcase-operations button {
    background: unset;
    border: unset;
}